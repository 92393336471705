* {
    box-sizing: border-box;
    margin: 0%;
    padding: 0%;
    font-family: "DIN Alternate", "GillSans", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 70;
  }
  
  div[class$="indicatorContainer"] {
    padding: 4px;
  }